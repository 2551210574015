var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-table",
        {
          ref: "table",
          attrs: {
            title: `${_vm.popupParam.requestContents}${_vm.$comm.getLangLabel(
              "LBL0001190"
            )}`,
            tableId: "4mImprRequest",
            columns: _vm.grid.columns,
            data: _vm.grid.data,
            gridHeight: _vm.grid.height,
            selection: "multiple",
            rowKey: "ram4mAssessImprId",
          },
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable
                    ? _c("c-btn", {
                        attrs: { label: "LBLADD", icon: "add" },
                        on: { btnClicked: _vm.addImpr },
                      })
                    : _vm._e(),
                  _vm.editable
                    ? _c("c-btn", {
                        attrs: { label: "LBLEXCEPT", icon: "remove" },
                        on: { btnClicked: _vm.removeImpr },
                      })
                    : _vm._e(),
                  _vm.editable
                    ? _c("c-btn", {
                        attrs: {
                          isSubmit: _vm.isSave,
                          url: _vm.saveUrl,
                          param: _vm.grid.data,
                          mappingType: "POST",
                          label: "LBLSAVE",
                          icon: "save",
                        },
                        on: {
                          beforeAction: _vm.saveImprRequest,
                          btnCallback: _vm.saveCallback,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }